import { StaticImport } from "next/dist/shared/lib/get-img-props";
import Image, { ImageProps } from "next/image";
import React from "react";

type Props = Omit<ImageProps, "alt" | "src"> & {
  src?: string | StaticImport;
  alt?: string;
  className?: string;
  innerStyle?: any;
};

export default function Placeholder({ innerStyle, ...props }: Props) {
  return (
    <Image
      style={
        innerStyle
          ? innerStyle
          : {
              width: "100%",
              height: "100%",
            }
      }
      quality={100}
      // blurDataURL="/image/default-image.webp"
      {...props}
      src={props.src || "/image/not-default.png"}
      alt={props.alt || process.env.NEXT_PUBLIC_COMPANY_NAME || "image"}
    />
  );
}
