import { BodyText } from "@/components/BodyText";
import React from "react";
import Placeholder from "@/components/Placeholder";

type Props = {
  text?: string;
  className?: string;
  children?: React.ReactNode;
};
export default function EmptyState({ text = "", className = "", children }: Props) {
  const context = text || "NO DATA";
  return (
    <div className={`c-flex flex-col my-6 ${className} select-none`}>
      <div className="max-w-[400px] max-h-[400px]">
        <Placeholder src="/image/empty-state.svg" width={400} height={400} quality={50} alt={context}></Placeholder>
      </div>
      <BodyText size={"xl"} className="text-gray-500">
        {context}
      </BodyText>
      {children}
    </div>
  );
}
